<template>
    <div class="supplyIndex">
        <div class="banner f-cl-c-c" @click="toNextPage"  :style="{ backgroundImage: `url(${backgroundImageUrl})` }">
            <span class="name">{{ supplyManagement.enterpriseName }}</span>
            <span class="mt-10 state" :style="{
            'color':
                supplyManagement.state == '012' || supplyManagement.state == '032' || supplyManagement.state == '042' ? 'red' : '#fff'
        }" v-if="supplyState">({{ supplyState }})</span>
        </div>
        <div class="Part f-c-b">
            <div class="LeftPart" @click="showDot ? toMessage() : ''">
                <img v-show="showDot" src="@/assets/supply/news.png" alt="">
                <div v-show="showDot" class="redDot"></div>
            </div>
            <el-breadcrumb class="f-c-e" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">
                    <img style="width: 16px;height: 14px;" src="@/assets/home.png" alt="">
                    <span> 当前位置： 首页</span>
                </el-breadcrumb-item>
                <el-breadcrumb-item>供应</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="featureList">
            <div class="FirstLine">
                <div class="title">
                    企业管理
                </div>
                <div class="flex line">
                    <div class="featureBox f-c-c" @click="intoOtherPage('customerList')">
                        <img class="imgSize1" src="../../assets/supply/customIndex.png" alt="">
                        <div>客户列表</div>
                    </div>

                    <div class="featureBox f-c-c PersonnelManage">
                        <img class="imgSize1" src="../../assets/supply/staffIndex.png" alt="">
                        <div>人员管理</div>
                        <div class="PersonnelManageList">
                            <div class="Blankspace">
                            </div>
                            <div class="PersonnelManageSublist f-c-c">
                                <div class="SublistItem f-c-b" @click="PersonnelManagementHandleClick(1)">
                                    <img src="../../assets/supply/employeeListIndex.png" alt="">
                                    <span>员工列表</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div class="SublistItem f-c-b" @click="PersonnelManagementHandleClick(2)">
                                    <img src="../../assets/supply/batchApprovalIndex.png" alt="">
                                    <span>权限批量管理</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div class="SublistItem f-c-b" @click="PersonnelManagementHandleClick(3)">
                                    <img src="../../assets/supply/personnelapprovalIndex.png" alt="">
                                    <span>人员审批</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div class="SublistItem f-c-b" @click="PersonnelManagementHandleClick(4)">
                                    <img src="../../assets/supply/recruitIndex.png" alt="">
                                    <span>招聘</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="featureBox f-c-c" @click="toNextPage(1)">
                        <img class="imgSize1" src="../../assets/supply/enterpriseIndex.png" alt="">
                        <div>企业信息</div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('workCard')">
                        <img class="imgSize2" style="width: 38px;height: 28px;" src="../../assets/supply/cardIndex.png"
                            alt="">
                        <div>工作牌</div>
                    </div>
                </div>

            </div>
            <div class="SecondLine">
                <div class="title">
                    产品管理
                </div>
                <div class="flex line">
                    <div class="featureBox f-c-c" @click="intoOtherPage('productLibrary')">
                        <img src="../../assets/supply/productIndex.png" alt="">
                        <div>产品库</div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('productLibraryAdd')">
                        <img src="../../assets/supply/newProductsIndex.png" alt="">
                        <div>新增产品</div>
                    </div>
                </div>
            </div>
            <div class="ThirdLine">
                <div class="title">
                    识料ERP（开单）
                </div>
                <div class="flex line">
                    <div class="featureBox f-c-c OrderGoodsManage">
                        <img class="imgSize1" src="../../assets/supply/orderIndex.png" alt="">
                        <div>订单管理</div>
                        <div class="OrderGoodsManageList">
                            <div class="Blankspace">
                            </div>
                            <div class="OrderGoodsManageSublist f-c-c">
                                <div class="SublistItem f-c-b" @click="OrderGoodsManageHandleClick('All')">
                                    <img src="../../assets/supply/employeeListIndex.png" alt="">
                                    <span>全部订单</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div class="SublistItem f-c-b" @click="OrderGoodsManageHandleClick('InProgress')">
                                    <img src="../../assets/supply/batchApprovalIndex.png" alt="">
                                    <span>进行中订单</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div class="SublistItem f-c-b" @click="OrderGoodsManageHandleClick('Completed')">
                                    <img src="../../assets/supply/personnelapprovalIndex.png" alt="">
                                    <span>已完成订单</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('addOrder')">
                        <img class="imgSize1" src="../../assets/supply/progressIndex.png" alt="">
                        <div>新增订单</div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('ReturnOrder')">
                        <img class="imgSize1" src="../../assets/supply/returnIndex.png" alt="">
                        <div>退货单列表</div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('orderStatistics')">
                        <img class="imgSize1" src="../../assets/supply/statisticsIndex.png" alt="">
                        <div>订单统计</div>
                    </div>
                    <div class="featureBox f-c-c FinancialManagement">
                        <img class="imgSize2" src="../../assets/supply/financiaIndex.png" alt="">
                        <div>财务管理</div>
                        <div class="FinancialManageList">
                            <div class="Blankspace">
                            </div>
                            <div class="FinancialManageSublist f-c-c">
                                <div @click="FinancialManageHandleClick('FinancialStatistics')"
                                    class="SublistItem f-c-b">
                                    <img src="../../assets/supply/financialstatisticsIndex.png" alt="">
                                    <span>财务统计</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                                <span style="border-right: 1px solid #C8DDE9;height: 30px;"></span>
                                <div @click="FinancialManageHandleClick('DocTemplate')" class="SublistItem f-c-b">
                                    <img src="../../assets/supply/documenttemplateIndex.png" alt="">
                                    <span>单据模板</span>
                                    <img src="../../assets/supply/rightarrow.png" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="featureBox f-c-c" @click="intoOtherPage('inventoryList')">
                        <img class="imgSize3" src="../../assets/supply/stockIndex.png" alt="">
                        <div>库存记录</div>
                    </div>

                </div>
                <div v-if="isShowMember" class="opacityBox f-c-c" @click="dialogVisible = true">
                    <img src="../../assets/supply/btn2.png" alt="btn2">
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="688px">
            <div class="w--100 f-c-c fs-30 col-33">
                <span >扫码打开识料小程序支付并开通会员</span>
            </div>
            <div class="f-c-c mt-30 mb-60">
                <img src="@/assets/supply/qrcode.png" alt="qrcode">
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    name: '',
    data() {
        return {
            supplyState: '',//企业状态
            showDot: false,//是否显示小红点
            supplyManagement: {},//供应商管理
            dialogPersonnelManagement: false,//弹出人员管理盒子
            dialogfinancialList: false,//弹出财务管理
            dialogOrderManagement: false,//弹出订单管理
            // 弹出登陆框
            orderListSort: [{
                title: '全部订单',
                type: 'All'
            }, {
                title: '进行中订单',
                type: 'InProgress'
            }, {
                title: '已完成订单',
                type: 'Completed'
            }, {
                title: '退货单',
                type: 'ReturnOrder'
            }],
            isDot: false,//是否显示
            enterpriseLevel: {},
            isShowMember:false,
            dialogVisible:false,
            backgroundImageUrl:'',
        }
    },
    created() {
        this.findEnterpriseAuditStateAndInfo();
        this.userFindJoinEnterprise();
        this.getEnterpriseLevel();
    },
    methods: {
        zhaoping() {
            this.$common.TemporarilyClosed()
        },
        // 用户查询企业状态以及信息接口
        findEnterpriseAuditStateAndInfo() {
            let that = this;
            that.$http.findEnterpriseAuditStateAndInfo({
                applicantId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token')
            }).then(res => {
                let { state } = res.data,
                    supplyState = '';
                switch (state) {
                    case '000':
                        supplyState = '未加入企业(点击加入企业)'
                        break;
                    case '011':
                        supplyState = '入驻审核中'
                        break;
                    case '012':
                    case '032':
                        supplyState = '审核不通过'
                        break;
                    case '031':
                        supplyState = '修改审核'
                        break;
                    case '041':
                        supplyState = '解散审核中'
                        break;
                    case '042':
                        supplyState = '解散审核不通过'
                        break;
                    case '051':
                        supplyState = '企业禁用中'
                        break;
                    case '061':
                        supplyState = '退出中'
                        break;
                }
                that.supplyManagement = res.data;
                let {enterpriseLevel} = that.supplyManagement
                that.supplyState = supplyState
                res.data.userPermission.p_m && res.data.userPermission.p_m != 'D' ? this.enterpriseApproval() : '';
                that.$setStorage('enterpriseStateInfo', res.data);
                switch(enterpriseLevel){
                    case 0:
                        this.backgroundImageUrl  = require('../../assets/supply/bannerSupplyIndex.png');
                    break;
                    case 1:
                        this.backgroundImageUrl  = require('../../assets/supply/banner1.png');
                    break;
                    case 2:
                        this.backgroundImageUrl  = require('../../assets/supply/banner2.png');
                    break;
                    case 3:
                        this.backgroundImageUrl  = require('../../assets/supply/banner3.png');
                    break;
                    case 4:
                        this.backgroundImageUrl  = require('../../assets/supply/banner4.png');
                    break;
                }


                
            })
        },
        // 用户查看申请或邀请加入企业列表接口
        userFindJoinEnterprise() {
            let that = this;
            that.$http.userFindJoinEnterprise({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page: 1,
                size: 20
            }).then(res => {
                if (res.code == 200) {
                    if (res.data.records.length > 0) {
                        that.showDot = true
                    }
                }
            })
        },
        // 企业审批人员列表接口
        enterpriseApproval() {
            let that = this;
            that.$http.enterpriseApproval({
                applyUserId: parseInt(that.$store.state.userId),
                token: that.$getStorage('token'),
                page: 1,
                size: 10
            }).then((res) => {
                if (res.code == 200) {
                    if (res.data.records && res.data.records.length) {
                        that.isDot = true
                    }
                }
            })
        },
        // 跳转
        toNextPage(data) {
            let { state } = this.supplyManagement
            // type = state == '012' ? 'enterpriseSettleAuditFailed' : (state == '031' ? 'process' : 'processUpdateFailed')
            if (data == 1) {
                switch (state) {
                    case '000':
                    case '011':
                        this.$common.message('未加入企业，不开放此功能', 'warning')
                        break;
                    case '012': //012--企业入驻审核不通过
                        this.$common.message('未加入企业，不开放此功能', 'warning')
                        break;
                    case '031': //031--审核
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'process'//审核
                                }))
                            }
                        })
                        break;
                    case '032': //032--企业修改信息审核不通过
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'processUpdateFailed'//审核
                                }))
                            }
                        })
                        break;
                    default:
                        this.$router.push({
                            path: '/SupplierInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    id: this.supplyManagement.enterpriseId,
                                    type: 'supply'
                                }))
                            }
                        })
                        break;
                }
            } else {
                switch (state) {
                    case '000':
                        this.$router.push('/JoinEnterpriseList')
                        break;
                    case '011'://入驻审核
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'processJoin'
                                }))
                            }
                        })
                        break;
                    case '012'://企业入驻审核不通过
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'enterpriseSettleAuditFailed'
                                }))
                            }
                        })
                        break;
                    case '031':
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'process'//审核
                                }))
                            }
                        })
                        break;
                    case '032'://企业修改审核不通过
                        this.$router.push({
                            path: '/EditEnterpriseInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'processUpdateFailed'//审核
                                }))

                            }
                        })
                        break;
                    // case '042'://企业修改审核不通过
                    //     this.$router.push({
                    //         path: '/EditEnterpriseInfo',
                    //         query: {
                    //             key: this.$UrlEncode.encode(JSON.stringify({
                    //                 type: 'processUpdateFailed'//审核
                    //             }))

                    //         }
                    //     })
                    //     break;
                    default://企业详情
                        this.$router.push({
                            path: '/SupplierInfo',
                            query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    id: this.supplyManagement.enterpriseId,
                                    type: 'supply'
                                }))
                            }
                        })
                        break;
                }
            }
        },

        intoOtherPage(data) {
            console.log('intoOtherPage')
            let { state, userPermission } = this.supplyManagement;
            if (state == '000' || state == '011' || state == '012') {
                this.$common.message('未加入企业，不开放此功能', 'warning')
            } else {
                switch (data) {
                    case 'workCard'://工作牌
                        this.$router.push({
                            name: 'WorkCard', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'workcard'
                                }))
                            }
                        })
                        break;
                    case 'personnelList'://人员管理
                        userPermission.p_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.dialogPersonnelManagement = true
                        break;
                    case 'customerList'://客户列表
                        userPermission.c_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/CustomerList')
                        break;
                    case 'productLibrary'://产品库
                        userPermission.p_l == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/ProductLibrary')
                        break;
                    case 'productLibraryAdd'://新增产品
                        userPermission.p_l == 'W' ? this.$router.push('/ProductLibraryAdd') : this.$common.message('暂无权限！', 'warning')
                        break;
                    case 'inventoryList'://库存管理
                        userPermission.i_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push('/InventoryRecords')
                        break;
                    case 'addOrder'://新增订单
                        userPermission.o_m == 'W' ? this.$router.push('/AddOrder') : this.$common.message('暂无权限！', 'warning')
                        break;
                    case 'ReturnOrder'://退货单列表
                        userPermission.o_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push({
                            name: 'OrderList', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: 'ReturnOrder'
                                }))
                            }
                        })
                        break;
                    case 'orderStatistics'://订单统计
                        userPermission.o_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.$router.push({
                            name: 'OrderStatistics', query: {
                                key: this.$UrlEncode.encode(JSON.stringify({
                                    type: ''
                                }))
                            }
                        })
                        break;
                    // case 'financialList'://财务管理
                    //     userPermission.f_s == 'D' ? this.$common.message('暂无权限！', 'warning') : this.dialogfinancialList = true
                    //     break;
                    // case 'orderList'://订单管理
                    //     userPermission.o_m == 'D' ? this.$common.message('暂无权限！', 'warning') : this.dialogOrderManagement = true
                    //     break;
                }
            }
        },
        // 人员管理按照权限进入
        PersonnelManagementHandleClick(data) {
            // a_m 人员权限管理	p_m 人员管理
            let { p_m, a_m } = this.supplyManagement.userPermission,
                { state } = this.supplyManagement;
            // 员工列表
            if (state == '000' || state == '011' || state == '012') { this.$common.message('未加入企业，不开放此功能', 'warning') } else {
                if (data == 1) {
                    p_m == 'D' ? this.$common.message('暂无权限查看！', 'warning') : this.$router.push('/EmployeeList')
                }
                // 权限批量管理
                if (data == 2) {
                    p_m == 'W' && a_m == 'W' ? this.$router.push('/PermissionBatchManagement') : this.$common.message('暂无权限查看！', 'warning')
                }
                // 人员审批
                if (data == 3) {
                    p_m == 'W' ? this.$router.push('/ApprovalInfo') : this.$common.message('暂无权限查看！', 'warning')
                }
                // 招聘
                if (data == 4) {
                    // p_m != 'D' ? this.$router.push('/RecruitList') : this.$common.message('暂无权限查看！', 'warning')
                    this.$common.message('此功能暂不开放', 'warning')
                }
            }

        },
        // 订单管理按照权限进入
        OrderGoodsManageHandleClick(data) {
            let { state } = this.supplyManagement,
                { o_m } = this.supplyManagement.userPermission;
            if (state == '000' || state == '011' || state == '012') {
                this.$common.message('未加入企业，不开放此功能', 'warning')
            } else if (o_m == 'D') {
                this.$common.message('暂无权限！', 'warning')
            } else {
                switch (data) {
                    case 'All':
                    this.$router.push({
                        name: 'OrderList', query: {
                            key: this.$UrlEncode.encode(JSON.stringify({
                                type: data
                            }))
                        }
                    });    
                    break;
                    case 'InProgress':
                    this.$router.push({
                        name: 'OrderListInProgress', query: {
                            key: this.$UrlEncode.encode(JSON.stringify({
                                type: data
                            }))
                        }
                    });    
                    break;
                    case 'Completed':
                    this.$router.push({
                        name: 'OrderListCompleted', query: {
                            key: this.$UrlEncode.encode(JSON.stringify({
                                type: data
                            }))
                        }
                    });    
                    break;
                }
                
            }
        },
        // 财务管理按照权限进入
        FinancialManageHandleClick(data) {
            let { state } = this.supplyManagement,
                { f_s } = this.supplyManagement.userPermission;
            if (state == '000' || state == '011' || state == '012') {
                this.$common.message('未加入企业，不开放此功能', 'warning')
            } else if (f_s == 'D') {
                this.$common.message('暂无权限！', 'warning')
            } else {
                this.$router.push(data)
            }
        },
        // 
        toMessage() {
            this.$router.push({
                path: '/EnterpriseNews'
            })
        },

        getEnterpriseLevel() {
            let that = this;
            that.$http.getEnterpriseLevel({
                userId: parseInt(that.$store.state.userId),
            }).then((res) => {
                if (res.code == 200) {
                    this.enterpriseLevel = res.data;
                    let { state } = this.supplyManagement;
                    switch (state) {
                        case '000':
                        case '011':
                        case '012':
                            that.isShowMember = false;
                            break;
                        default:
                            that.isShowMember = that.enterpriseLevel.enterpriseLevel == 0 ? true : false;
                    }
                }
            })
        }
    },
}

</script>
<style lang='less' scoped>
.supplyIndex {
    width: 980px;

    .banner {
        // background: url('../../assets/supply/bannerSupplyIndex.png');
        height: 200px;
        color: #FFFFFF;
        text-align: center;
        margin-bottom: 40px;
        cursor: pointer;
        background-size: cover;

        .name {
            font-size: 36px;
        }

        .state {
            font-size: 28px;
        }
    }

    .Part {
        padding: 20px 0;

        .LeftPart {
            width: 120px;
            position: relative;

            img {
                cursor: pointer;
            }

            .redDot {
                border-radius: 50%;
                background-color: red;
                width: 15px;
                height: 15px;
                position: absolute;
                right: -5px;
                top: -5px;
                cursor: pointer;
            }
        }
    }

    .el-breadcrumb ::v-deep .el-breadcrumb__inner {
        color: #666666 !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .el-breadcrumb__item:last-child ::v-deep .el-breadcrumb__inner {
        color: #0363FA !important;
        font-weight: 400 !important;
        font-size: 14px;
    }

    .featureList {
        padding-top: 20px;
        border-top: 1px solid #F8F8F8;

        .FirstLine {
            .title {
                font-size: 22px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
                line-height: 60px;
            }

            .line {
                position: relative;

                .featureBox {

                    .imgSize1 {
                        width: 32px;
                        height: 32px;
                        margin-right: 20px;
                    }

                    .imgSize2 {
                        width: 38px;
                        height: 28px;
                        margin-right: 20px;
                    }

                    height: 100px;
                    width: 205px;
                    background: #F4FAFD;
                    border-radius: 15px;
                    margin-right: 10px;

                    cursor: pointer;
                }

                .featureBox:hover {
                    background: #E1F1FA;
                }

                .PersonnelManage {
                    position: relative;

                    .PersonnelManageList {
                        display: none;
                        position: absolute;
                        top: 100px;
                        left: 0px;
                        width: 820px;
                        height: 110px;

                        .Blankspace {
                            height: 10px;
                            width: 205px;
                            background: #E1F1FA;
                        }

                        .PersonnelManageSublist {
                            height: 100px;
                            width: 100%;
                            background: #E1F1FA;
                            border-radius: 0 15px 15px 15px;

                            .SublistItem {
                                height: 100px;
                                width: 205px;
                                padding: 0 30px;

                                span {
                                    font-size: 16px;
                                    color: #000000;
                                }
                            }

                            .SublistItem:hover:first-child {
                                background: #F4FAFD;
                                border-radius: 0 0 0 15px;
                            }

                            .SublistItem:hover:last-child {
                                background: #F4FAFD;
                                border-radius: 0 15px 15px 0;
                            }

                            .SublistItem:hover {
                                background: #F4FAFD;
                            }
                        }
                    }
                }

                .PersonnelManage:hover {
                    border-radius: 15px 15px 0 0;
                    margin-bottom: 130px;

                    .PersonnelManageList {
                        display: block;
                    }
                }
            }

        }

        .SecondLine {
            .title {
                font-size: 22px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
                line-height: 60px;
            }

            .line {
                .featureBox {
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 25px;
                    }

                    height: 100px;
                    width: 205px;
                    background: #F4FAFD;
                    border-radius: 15px;
                    margin-right: 10px;

                    cursor: pointer;
                }

                .featureBox:hover {
                    background: #E1F1FA;
                }
            }

        }

        .ThirdLine {
            position: relative;

            .title {
                font-size: 22px;
                font-family: HarmonyOS Sans SC;
                font-weight: 500;
                color: #333333;
                line-height: 60px;
            }

            .line {
                .featureBox {

                    .imgSize1 {
                        width: 27px;
                        height: 32px;
                        margin-right: 25px;
                    }

                    .imgSize2 {
                        width: 33px;
                        height: 33px;
                        margin-right: 25px;
                    }

                    .imgSize3 {
                        width: 33px;
                        height: 36px;
                        margin-right: 25px;
                    }

                    height: 100px;
                    width: 203px;
                    background: #F4FAFD;
                    border-radius: 15px;
                    margin-right: 10px;
                    cursor: pointer;
                }


                .featureBox:hover {
                    background: #E1F1FA;
                }

                .OrderGoodsManage {
                    position: relative;

                    .OrderGoodsManageList {
                        display: none;
                        position: absolute;
                        top: 100px;
                        left: 0px;
                        // border: 1px solid red;
                        width: 615px;
                        height: 110px;

                        .Blankspace {
                            height: 10px;
                            width: 203px;
                            background: #E1F1FA;
                        }

                        .OrderGoodsManageSublist {
                            height: 100px;
                            width: 100%;
                            background: #E1F1FA;
                            border-radius: 0 15px 15px 15px;

                            .SublistItem {
                                height: 100px;
                                width: 203px;
                                padding: 0 30px;

                                span {
                                    font-size: 16px;
                                    // font-family: HarmonyOS Sans SC;
                                    // font-weight: 300;
                                    color: #000000;
                                }
                            }

                            .SublistItem:hover:first-child {
                                background: #F4FAFD;
                                border-radius: 0 0 0 15px;
                            }

                            .SublistItem:hover:last-child {
                                background: #F4FAFD;
                                border-radius: 0 15px 15px 0;
                            }

                            .SublistItem:hover {
                                background: #F4FAFD;
                            }
                        }
                    }
                }

                .OrderGoodsManage:hover {
                    border-radius: 15px 15px 0 0;
                    margin-bottom: 130px;

                    .OrderGoodsManageList {
                        display: block;
                    }
                }

                .FinancialManagement {
                    position: relative;

                    .FinancialManageList {
                        display: none;
                        // display: block;
                        position: absolute;
                        top: 100px;
                        left: 0px;
                        // border: 1px solid red;
                        width: 406px;
                        height: 110px;

                        .Blankspace {
                            height: 10px;
                            width: 203px;
                            background: #E1F1FA;
                        }

                        .FinancialManageSublist {
                            height: 100px;
                            width: 100%;
                            background: #E1F1FA;
                            border-radius: 0 15px 15px 15px;

                            .SublistItem {
                                height: 100px;
                                width: 203px;
                                padding: 0 30px;

                                span {
                                    font-size: 16px;
                                    // font-family: HarmonyOS Sans SC;
                                    // font-weight: 300;
                                    color: #000000;
                                }
                            }

                            .SublistItem:hover:first-child {
                                background: #F4FAFD;
                                border-radius: 0 0 0 15px;
                            }

                            .SublistItem:hover:last-child {
                                background: #F4FAFD;
                                border-radius: 0 15px 15px 0;
                            }

                            .SublistItem:hover {
                                background: #F4FAFD;
                            }
                        }
                    }
                }

                .FinancialManagement:hover {
                    border-radius: 15px 15px 0 0;
                    margin-bottom: 130px;

                    .FinancialManageList {
                        display: block;
                    }
                }


            }

            .opacityBox {
                background: url('../../assets/supply/bg2.png') no-repeat;
                width: 1265px;
                height: 100px;
                position: absolute;
                left: -5px;
                top: 55px;

                img {
                    cursor: pointer;
                }
            }


        }
    }

}
</style>
